import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import Link from 'ui-kit/link/link';

import ErrorScreenLayout from 'components/layouts/error-screen/error-screen.layout';

import { getPhoneNumber } from 'util/globalVariables';

import useResetToken, { flowTypes } from 'hooks/useResetToken';

import './session-expired.style.scss';

const SessionExpired = ({ data, location }: { data: GatsbyTypes.SessionExpiredDataQuery; location: Location }) => {
    const { t } = useTranslation();

    const params = new URLSearchParams(location.search);
    const flowParameter = params.get('flow');

    useResetToken(flowParameter as flowTypes);

    const renderSubtitle = (keyPrefix: string, flowParameter?: string | null) => {
        switch (flowParameter) {
            case 'join-membership-plan':
                return (
                    <>
                        <p className="mobile-auto-refill-subtitle1">{t(`${keyPrefix}.paragraphOne`)}</p>
                        <p className="mobile-auto-refill-subtitle2">{t(`${keyPrefix}.paragraphTwo`)}</p>
                        <p
                            className="mobile-auto-refill-subtitle2"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t(`${keyPrefix}.paragraphThree`, { phoneNumber: getPhoneNumber({ isEnd: true }) })
                                )
                            }}
                        />
                    </>
                );
            default:
                return (
                    <>
                        <p className="mobile-auto-refill-subtitle1">{t(`${keyPrefix}.paragraphOne`)}</p>

                        <p
                            className="mobile-auto-refill-subtitle2"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t(`${keyPrefix}.paragraphTwo`, { phoneNumber: getPhoneNumber({ isEnd: true }) })
                                )
                            }}
                        />
                        <p className="mobile-auto-refill-subtitle2">{t(`${keyPrefix}.paragraphThree`)}</p>
                    </>
                );
        }
    };

    const renderFlowContent = () => {
        if (flowParameter) {
            switch (flowParameter) {
                case 'auto-refill':
                    return renderSubtitle('pages.sessionExpired.autoRefillSubtitle');
                case 'easy-refill':
                    return renderSubtitle('pages.sessionExpired.easyRefillSubtitle');
                case 'caregiver-access':
                    return renderSubtitle('pages.sessionExpired.caregiverAccessSubtitle');
                case 'join-membership-plan':
                    return renderSubtitle('pages.sessionExpired.joinMembershipPlanSubtitle', flowParameter);
                default:
                    return renderSubtitle('pages.sessionExpired.subtitle');
            }
        }
        return renderSubtitle('pages.sessionExpired.subtitle');
    };

    const getButtonLabel = (flowParameter: string | null) => {
        switch (flowParameter) {
            case 'auto-refill':
            case 'easy-refill':
            case 'caregiver-access':
                return t('pages.sessionExpired.buttonLabelAutoRefill');
            case 'join-membership-plan':
                return t('pages.sessionExpired.buttonLabelExploreBirdi');
            default:
                return t('pages.sessionExpired.buttonLabel');
        }
    };

    const getButtonTo = (flowParameter: string | null) => {
        switch (flowParameter) {
            case 'join-membership-plan':
                return '/';
            default:
                return '/sign-in';
        }
    };

    return (
        <ErrorScreenLayout title={t('pages.sessionExpired.title')}>
            <Container className="session-expired">
                <Row>
                    <Col xs={12} lg={{ span: 8, offset: 2 }}>
                        {renderFlowContent()}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} className="d-flex align-items-center justify-content-center">
                        <Link
                            to={getButtonTo(flowParameter)}
                            label={getButtonLabel(flowParameter)}
                            variant="cta-button"
                            dataGALocation="SessionExpired-Signin"
                            ariaLabel="SessionExpired-Signin"
                        />
                    </Col>
                </Row>
            </Container>
        </ErrorScreenLayout>
    );
};

export default SessionExpired;

export const query = graphql`
    query SessionExpiredData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
